import { mapActions, mapGetters, mapMutations } from "vuex"
import ThemisMfaSetup from "@/components/login/mfa-setup"

export default {
  name      : "Login",
  components: {
    ThemisMfaSetup
  },
  data() {
    return {
      credentials: {
        email      : undefined,
        password   : undefined,
        newPassword: undefined,
        totp       : undefined
      },
      showPassword      : false,
      passwordValidation: {
        minimumCharactersPresent         : false,
        minimumNumbersPresent            : false,
        minimumUpperCaseCharactersPresent: false,
        minimumLowerCaseCharactersPresent: false,
        minimumSpecialCharactersPresent  : false
      },
      isPasswordValid      : false,
      showPasswordRules    : undefined,
      showUserDisabledAlert: false
    }
  },
  computed: {
    ...mapGetters({
      clientName            : "auth/clientName",
      isInvalidCredentials  : "auth/isInvalidCredentials",
      hasExceededAttempts   : "auth/hasExceededAttempts",
      isLoggedIn            : "auth/isLoggedIn",
      isLoggingIn           : "auth/isLoggingIn",
      isNewPasswordRequired : "auth/isNewPasswordRequired",
      isTotpRequired        : "auth/isTotpRequired",
      isInvalidTotp         : "auth/isInvalidTotp",
      configurations        : "configurations/configurations",
      isDefaultSsoEnabled   : "configurations/isDefaultSsoEnabled",
      clientConfigurationId : "configurations/clientConfigurationId",
      isLoggedInUserDisabled: "auth/isLoggedInUserDisabled",
      secretCode            : "auth/secretCode",
      isTokenVerified       : "auth/isTokenVerified",
      isVerifyingToken      : "auth/isVerifyingToken",
      isEnforceMfaEnabled   : "configurations/isEnforceMfaEnabled",
      loggedInUser          : "auth/loggedInUser"
    }),
    cardHeight() {
      if (this.hasToUseCredentialsToLogin) {
        return "711"
      }
      return "308"
    },
    isSupportUser() {
      return this.$route.query["support-user"] === "true"
    },
    hasToUseCredentialsToLogin() {
      return !this.isDefaultSsoEnabled || this.isSupportUser
    },
    isMfaSetupRequired() {
      return this.isEnforceMfaEnabled && this.loggedInUser && !this.loggedInUser.mfaEnabled
    }
  },
  methods: {
    ...mapActions({
      loginUsingCredentials   : "auth/loginUsingCredentials",
      loginUsingIDP           : "auth/loginUsingIDP",
      sendMFACode             : "auth/sendMFACode",
      notify                  : "shared/notify",
      generateSharedSecretCode: "auth/generateSharedSecretCode",
      verifySecretToken       : "auth/verifySecretToken",
      setLoggedInUser         : "auth/setLoggedInUser",
      assignUserMfaPreference : "auth/assignUserMfaPreference"
    }),
    ...mapMutations({
      setInvalidTotp: "auth/setInvalidTotp"
    }),
    login(){
      if (this.hasToUseCredentialsToLogin) {
        this.loginUsingCredentials(this.credentials)

      } else {
        this.loginUsingIDP()
      }
    },
    /**
     * This method routes the handle to forgot-password page.
     */
    handleForgotPassword() {
      this.$router.push({ name: "forgot-password" })
    }
  },
  watch: {
    isLoggedIn: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue && !this.isMfaSetupRequired) {
          this.$router.push({ name: "dashboard" })
        }
      }
    },
    isInvalidCredentials: function(newValue) {
      if (newValue) {
        this.notify({
          type: "error",
          text: "426",
          icon: "mdi-alert-circle"
        })
      }
    },
    hasExceededAttempts: function(newValue) {
      if (newValue) {
        this.notify({
          type: "error",
          text: "427",
          icon: "mdi-alert-circle"
        })
      }
    },
    "credentials.newPassword": function(newValue) {
      this.passwordValidation = {
        minimumCharactersPresent         : newValue?.length >= 12,
        minimumNumbersPresent            : newValue?.match(/\d/),
        minimumUpperCaseCharactersPresent: newValue?.match(/[A-Z]/),
        minimumLowerCaseCharactersPresent: newValue?.match(/[a-z]/),
        minimumSpecialCharactersPresent  : newValue?.match(/[!@#$%^&*]/)
      }
      this.isPasswordValid    = this.passwordValidation.minimumCharactersPresent &&
         this.passwordValidation.minimumNumbersPresent &&
         this.passwordValidation.minimumUpperCaseCharactersPresent &&
         this.passwordValidation.minimumLowerCaseCharactersPresent &&
         this.passwordValidation.minimumSpecialCharactersPresent
    },
    "credentials.totp": {
      immediate: true,
      handler  : function() {
        if (this.isInvalidTotp) {
          this.setInvalidTotp(false)
        }
      }
    },
    isLoggedInUserDisabled: {
      immediate: true,
      handler  : function(newValue) {
        this.showUserDisabledAlert = newValue
      }
    },
    isMfaSetupRequired: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.generateSharedSecretCode()
        }
      }
    },
    isTokenVerified: {
      immediate: true,
      handler  : async function(newValue) {
        if (newValue) {
          this.assignUserMfaPreference(true)
          this.$router.push({ name: "dashboard" })
        }
      }
    }
  }
}