import { format } from "@/utils"
import qrCode from "qrcode"

export default {
  name: "MfaSetup",
  data() {
    return {
      showDialog    : true,
      showSecretCode: false,
      qrCodeUrl     : undefined,
      totp          : undefined
    }
  },
  props: {
    pCanClose        : Boolean,
    pSecretCode      : String,
    pIsVerifyingToken: Boolean,
    pIsInvalidTotp   : Boolean
  },
  watch: {
    pSecretCode: {
      handler: function(newValue) {
        if (newValue) {
          const otpAuthUrl = format(this.$CONSTANTS.MFA.TOTP_URL, window.location.host, `secret=${newValue}`)
          qrCode.toDataURL(otpAuthUrl)
            .then(url => this.qrCodeUrl = url)
            .catch(() => this.qrCodeUrl = undefined)
        }
      }
    }
  }
}